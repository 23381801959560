const PUBLIC = {
  OVERVIEW: {
    PATH: '/make-a-will-online',
    VIEW: 'Overview',
  },
  FOR_COUPLES: {
    PATH: '/wills/wills-for-couples',
    VIEW: 'ForCouples',
  },
  PRICING: {
    PATH: '/wills/pricing',
    VIEW: 'Pricing',
  },
  TELEPHONE: {
    PATH: '/wills/telephone-wills',
    VIEW: 'TelephoneWills',
  },
  REFERRAL: {
    PATH: '/referral',
    VIEW: 'Referral',
  },
  MGM_REFERRAL: {
    PATH: '/invite/wills/mgm',
    VIEW: 'Referral',
  },
  ONLINE_WILLS_TERMS: {
    PATH: '/wills/online-wills-terms',
    VIEW: 'Terms',
  },
  TELEPHONE_WILLS_TERMS: {
    PATH: '/wills/telephone-wills-terms',
    VIEW: 'Terms',
  },
  MARKETING_ASSISTANT_TERMS: {
    PATH: '/wills/marketing-assistant-terms',
    VIEW: 'Terms',
  },
}

const PPC = {
  SOLICITORS: {
    PATH: '/landing/wills/write-a-will',
    VIEW: 'Solicitors',
  },
  TRUSTS: {
    PATH: '/landing/wills/property-trusts',
    VIEW: 'Trusts',
  },
}

export const ROUTES = { PUBLIC, PPC }
